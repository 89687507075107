<template>
  <section>
    <b-container class="py-md-4">
      <div :class="[style['register_wrapper'], 'my-md-5 my-4 p-3 text-white']">
        <router-link
          to="/"
          class="navbar-brand font-weight-bold text-center my-6 px-2 d-none d-lg-inline-block"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            class="register-logo"
          >
        </router-link>
        <b-row class="justify-content-center justify-content-lg-start">
          <b-col
            xl="8"
            lg="8"
          >
            <div class="text-center text-lg-left pt-5 mb-5">
              <img
                class="mb-3"
                src="../assets/imagesv2/ic-forgot-password.png"
                alt=""
              >
              <h1 class="title display-5 font-weight-bold mb-4">
                Verify OTP
              </h1>
              <p class="font-open-sans mb-1">
                Enter the verification code/OTP that has been sent to your
                email.
              </p>
            </div>
            <b-row
              :class="[
                'mb-5 justify-content-center justify-content-lg-start',
                style['forgot-pwd-form'],
              ]"
            >
              <b-col
                xl="8"
                lg="10"
                :class="['pl-md-0', style['otp-input']]"
              >
                <b-row
                  class="mb-5 justify-content-center justify-content-lg-start flex-row"
                >
                  <b-col
                    v-for="(input, index) in pinLength"
                    :key="index"
                    cols="3"
                  >
                    <b-form-input
                      v-model="otp_numbers[index]"
                      class="text-center"
                      :formatter="formatOtp"
                      type="number"
                      maxLength="1"
                      @keyup="($event) => moveToNext($event, index)"
                      @keyup.left="($event) => moveToPrev($event, index)"
                    />
                  </b-col>
                </b-row>
                <b-form-input
                  v-model="code"
                  v-validate="{ required: true }"
                  :state="validateState('code')"
                  class="d-none"
                  name="code"
                />
                <b-form-invalid-feedback id="code">
                  {{ veeErrors.first("code") }}
                </b-form-invalid-feedback>
                <!-- <b-input-group class="mt-3">
                      <b-form-input
                        id="code"
                        v-model="code"
                        v-validate="{ required: true, length: 4 }"
                        type="number"
                        name="code"
                        :state="validateState('code')"
                        data-vv-as="Code"
                        required
                        class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                        :formatter="formatOtp"
                      />
                      <b-form-invalid-feedback id="code">
                        {{ veeErrors.first("code") }}
                      </b-form-invalid-feedback>
                    </b-input-group> -->
                <b-overlay
                  :show="isLoading"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="mt-4"
                >
                  <b-button
                    ref="button"
                    :disabled="isLoading"
                    variant="golden"
                    class="btn-pop text-uppercase font-weight-bold bg-golden"
                    squared
                    block
                    @click="handleNext"
                  >
                    SUBMIT
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
            <div class="text-right mb-5" />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import { _sendResponse } from "../js/common-function";
import { APIUrlList } from "../js/api_url";
import style from "../styles/registration.module.scss";
import logo from "../assets/logo/logo-white-text.png";

export default {
    name: "ForgotPassword",
    data() {
        return {
            logo,
            style,
            url: null,
            //code: "",
            isLoading: false, // join button click load
            pinLength: 4,
            otp_numbers: [],
        };
    },
    computed: {
        code() {
            return this.otp_numbers.join("");
        },
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleNext() {
            this.isLoading = true;
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }
                this.isLoading = true;
                let formData = new FormData();
                if (this.$route.params.id !== undefined) {
                    formData.append("userId", this.$route.params.id);
                }
                formData.append("code", this.code);
                axios
                    .post(API_BASE_URL + APIUrlList.VERIFYCODE, formData)
                    .then((response) => {
                        if (response.data.status === false) {
                            _sendResponse("error", response.data.message);
                            return;
                        }
                        _sendResponse("success", response.data.message);
                        this.$router.push('/');
                    })
                    .catch((error) => {
                        _sendResponse("error", error.response.data.errors);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            });
        },
        formatOtp(e) {
            return String(e).substring(0, 1);
        },
        moveToNext(e, index) {
            if (e.key != "Backspace" && e.key != "Delete" && e.key != "ArrowLeft") {
                if (index + 1 != this.pinLength) {
                    document.getElementsByTagName("INPUT")[index + 1].focus();
                }
            }
        },
        clearAndMoveToPrev() {
            //@keyup.delete="$event => moveToPrev($event, index)"
            //e, index
            /* if (this.code != "" && (index - 1) != -1 && index != 0) {
                console.log(this.otp_numbers[index]);
                if (this.otp_numbers[index] == ""){
                    document.getElementsByTagName('INPUT')[index - 1].focus();
                }
                
            } */
        },
        moveToPrev(e, index) {
            if (index - 1 != -1 && index != 0) {
                if (this.otp_numbers[index] == "") {
                    document.getElementsByTagName("INPUT")[index - 1].focus();
                }
            }
        },
    },
};
</script>
